/**
 * Indicates if a value is a {@link MarshalledSourceReference}
 * @param value
 */
function isMarshalledSourceReference(value) {
    return (typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean" ||
        typeof value === "bigint");
}
/**
 * Indicates if a value is a {@link SourceReference}
 * @param value
 */
function isSourceReference(value) {
    return (typeof value === "symbol" ||
        isMarshalledSourceReference(value));
}

function asyncIterator(value) {
    return asyncIterable(value)[Symbol.asyncIterator]();
}
function asyncIterable(value) {
    if (isAsyncIterable(value)) {
        return value;
    }
    return {
        [Symbol.asyncIterator]: async function* () {
            if (!isIterable(value)) {
                return;
            }
            for (const item of value) {
                yield item;
            }
        }
    };
}
function isAsyncIterable(value) {
    function isAsyncIterableInstance(value) {
        return !!value;
    }
    return !!(isAsyncIterableInstance(value) &&
        value[Symbol.asyncIterator] instanceof Function);
}
function isIterable(value) {
    function isIterableInstance(value) {
        return !!value;
    }
    return !!(isIterableInstance(value) &&
        value[Symbol.iterator] instanceof Function);
}
function isIterableIterator(value) {
    function isIteratorLike(value) {
        return typeof value === "object";
    }
    return (isIteratorLike(value) &&
        value.next instanceof Function &&
        (isAsyncIterable(value) ||
            isIterable(value)));
}
function isPromise(value) {
    function isPromiseLike(value) {
        return typeof value === "object";
    }
    return (isPromiseLike(value) &&
        value.then instanceof Function);
}
async function getNext(iterator, value) {
    let next;
    try {
        next = iterator.next(value);
        if (isPromise(next)) {
            next = await next;
        }
        if (next.done) {
            if (iterator.return) {
                next = iterator.return(value);
                if (isPromise(next)) {
                    next = await next;
                }
            }
        }
        return next;
    }
    catch (e) {
        if (!iterator.throw) {
            throw e;
        }
        next = iterator.throw(e);
        if (isPromise(next)) {
            next = await next;
        }
        return next;
    }
}

async function asyncDrain(iterable) {
    if (!iterable) {
        return;
    }
    const iterator = asyncIterator(iterable);
    let next, anyValue = false;
    do {
        next = await iterator.next();
        anyValue = anyValue || !next.done;
    } while (!next.done);
    return anyValue;
}
function drain(iterable) {
    if (!iterable) {
        return;
    }
    const iterator = iterable[Symbol.iterator]();
    let next, anyValue = false;
    do {
        next = iterator.next();
        anyValue = anyValue || !next.done;
    } while (!next.done);
    return anyValue;
}

function negateIfNeeded(negate, value) {
    return negate ? !value : value;
}
function* filterNegatable(iterable, callbackFn, negate = false, thisValue, parent) {
    for (const value of iterable) {
        if (negateIfNeeded(negate, callbackFn.call(thisValue, value, parent))) {
            yield value;
        }
    }
}
async function* asyncFilterNegatable(iterable, callbackFn, negate = false, thisValue, parent) {
    for await (const value of asyncIterable(iterable)) {
        if (negateIfNeeded(negate, await callbackFn.call(thisValue, value, parent))) {
            yield value;
        }
    }
}
function filter(iterable, callbackFn, thisValue, parent) {
    return filterNegatable(iterable, callbackFn, false, thisValue, parent);
}
function asyncFilter(iterable, callbackFn, thisValue, parent) {
    return asyncFilterNegatable(iterable, callbackFn, false, thisValue, parent);
}

function except(iterable, callbackFn, thisValue, parent) {
    return filterNegatable(iterable, callbackFn, true, thisValue, parent);
}
function asyncExcept(iterable, callbackFn, thisValue, parent) {
    return asyncFilterNegatable(iterable, callbackFn, true, thisValue, parent);
}

function hasAny(iterable) {
    const iterator = iterable[Symbol.iterator]();
    const result = iterator.next();
    return !result.done;
}
async function asyncHasAny(iterable) {
    const iterator = asyncIterable(iterable)[Symbol.asyncIterator]();
    const result = await iterator.next();
    return !result.done;
}

function every(iterable, callbackFn, thisValue, parent) {
    const iterableResult = except(iterable, callbackFn, thisValue, parent);
    return !hasAny(iterableResult);
}
async function asyncEvery(iterable, callbackFn, thisValue, parent) {
    const iterableResult = asyncExcept(iterable, callbackFn, thisValue, parent);
    return !await asyncHasAny(iterableResult);
}

function forEach(iterable, callbackFn, thisValue, parent) {
    for (const value of iterable) {
        callbackFn.call(thisValue, value, parent);
    }
}
async function forEachAsync(iterable, callbackFn, thisValue, parent) {
    for await (const value of asyncIterable(iterable)) {
        await callbackFn.call(thisValue, value, parent);
    }
}

function* map(iterable, callbackFn, thisValue, parent) {
    for (const value of iterable) {
        yield callbackFn.call(thisValue, value, parent);
    }
}
async function* asyncMap(iterable, callbackFn, thisValue, parent) {
    for await (const value of asyncIterable(iterable)) {
        yield callbackFn.call(thisValue, value, parent);
    }
}

function reduce(iterable, callbackFn, initialValue, thisValue, parent) {
    let accumulator = initialValue;
    for (const value of iterable) {
        if (accumulator == undefined) {
            accumulator = value;
            continue;
        }
        accumulator = callbackFn.call(thisValue, accumulator, value, parent);
    }
    return accumulator;
}
async function asyncReduce(iterable, callbackFn, initialValue, thisValue, parent) {
    let accumulator = initialValue;
    for await (const value of asyncIterable(iterable)) {
        if (accumulator == undefined) {
            accumulator = value;
            continue;
        }
        accumulator = await callbackFn.call(thisValue, accumulator, value, parent);
    }
    return accumulator;
}

function some(iterable, callbackFn, thisValue, parent) {
    const iterableResult = filter(iterable, callbackFn, thisValue, parent);
    return hasAny(iterableResult);
}
async function asyncSome(iterable, callbackFn, thisValue, parent) {
    const iterableResult = asyncFilter(iterable, callbackFn, thisValue, parent);
    return asyncHasAny(iterableResult);
}

function* union(left, right) {
    for (const value of left) {
        yield value;
    }
    for (const value of right) {
        yield value;
    }
}
async function* asyncUnion(left, right) {
    for await (const value of asyncIterable(left)) {
        yield value;
    }
    for await (const value of asyncIterable(right)) {
        yield value;
    }
}

function arrayRetainer(has) {
    const values = [];
    return {
        add: value => {
            if (has && has(value, values)) {
                return;
            }
            values.push(value);
        },
        [Symbol.iterator]: () => values[Symbol.iterator]()
    };
}
function setRetainer() {
    return new Set();
}
function retain(iterable, retainer = arrayRetainer()) {
    const iterator = iterable[Symbol.iterator]();
    function* generator() {
        for (const value of retainer) {
            yield value;
        }
        let next;
        do {
            next = iterator.next();
            if (next.done) {
                continue;
            }
            // If we're not adding it to our retainer, then the value
            // will never be added in the same order
            // this means that when the iterable is run again, that values
            // will show up in _a different order_
            //
            // This is no good for ordered sets
            //
            // Specifically MDN describes a sets iterator as:
            //
            // Set.prototype[@@iterator]()
            // Returns a new Iterator object that contains the values for each element in the Set object in insertion order.
            //
            // We should follow that if it is available
            if (retainer.has && retainer.has(next.value)) {
                continue;
            }
            retainer.add(next.value);
            yield next.value;
        } while (!next.done);
    }
    return {
        [Symbol.iterator]: generator
    };
}
function asyncRetain(iterable, retainer = arrayRetainer()) {
    const iterator = asyncIterator(iterable);
    async function* generator() {
        for await (const value of asyncIterable(retainer)) {
            yield value;
        }
        let next;
        do {
            next = await iterator.next();
            if (next.done) {
                continue;
            }
            // See explanation @ sync version of retain
            if (retainer.has && await retainer.has(next.value)) {
                continue;
            }
            await retainer.add(next.value);
            yield next.value;
        } while (!next.done);
    }
    return {
        [Symbol.asyncIterator]: generator
    };
}

function toArray(iterable) {
    return Array.from(iterable);
}
async function asyncToArray(iterable) {
    const result = [];
    for await (const value of asyncIterable(iterable)) {
        result.push(value);
    }
    return result;
}

function mask(iterable, maskIterable) {
    return maskReversible(iterable, maskIterable, false);
}
function asyncMask(iterable, maskIterable) {
    return asyncMaskReversible(iterable, maskIterable, false);
}
function* maskReversible(iterable, maskIterable, reverse = false) {
    const iterator = iterable[Symbol.iterator]();
    const maskIterator = maskIterable[Symbol.iterator]();
    let next, nextMask;
    do {
        nextMask = maskIterator.next();
        if (reverse === true && nextMask.done) {
            break;
        }
        next = iterator.next();
        if (next.done) {
            break;
        }
        // if `reverse` is `true` and if `maskIterable` returns `true` then I want the value to be used, if it is `done` then I want to finish
        if (reverse === true && nextMask.value === true) {
            yield next.value;
        }
        // if reverse is false and if maskIterable returns `true` then I want the value to be skipped, if it is `done` then I want to finish
        if (reverse === false && nextMask.value !== true) {
            yield next.value;
        }
    } while (!next.done);
    if (iterator.return) {
        iterator.return();
    }
}
async function* asyncMaskReversible(iterable, maskIterable, reverse = false) {
    const iterator = asyncIterator(iterable);
    const maskIterator = asyncIterator(maskIterable);
    let next, nextMask;
    do {
        nextMask = await maskIterator.next();
        // If we have reversed, then as soon as mask
        if (reverse && nextMask.done) {
            break;
        }
        next = await iterator.next();
        if (next.done) {
            break;
        }
        // If no value, we're done
        // If mask has a value, we want to ignore
        if (nextMask.value !== reverse) {
            continue;
        }
        yield next.value;
    } while (!next.done);
    if (iterator.return) {
        await iterator.return();
    }
}
function* skip(count) {
    for (let remaining = count; remaining > 0; remaining -= 1) {
        yield true;
    }
}
function* take(count) {
    for (let remaining = count; remaining > 0; remaining -= 1) {
        yield true;
    }
}

function* flatMap(iterable, callbackFn, thisValue, parent) {
    for (const value of iterable) {
        const newIterable = callbackFn.call(thisValue, value, parent);
        for (const value of newIterable) {
            yield value;
        }
    }
}
async function* asyncFlatMap(iterable, callbackFn, thisValue, parent) {
    for await (const value of asyncIterable(iterable)) {
        const newIterable = await callbackFn.call(thisValue, value, parent);
        for await (const value of asyncIterable(newIterable)) {
            yield value;
        }
    }
}

function distinctRetainer(equalityFn) {
    if (!equalityFn) {
        return new Set();
    }
    const values = [];
    return {
        has(value) {
            for (const otherValue of values) {
                if (equalityFn(otherValue, value)) {
                    return true;
                }
            }
            return false;
        },
        add(value) {
            values.push(value);
        },
        [Symbol.iterator]: values[Symbol.iterator].bind(values)
    };
}
function* distinct(iterable, equalityFn) {
    const retainer = distinctRetainer(equalityFn);
    for (const value of iterable) {
        if (retainer.has(value)) {
            continue;
        }
        retainer.add(value);
        yield value;
    }
}
function asyncDistinctRetainer(equalityFn) {
    if (!equalityFn) {
        return setRetainer();
    }
    const values = [];
    return {
        async has(value) {
            for (const otherValue of values) {
                if (await equalityFn(otherValue, value)) {
                    return true;
                }
            }
            return false;
        },
        async add(value) {
            values.push(value);
        },
        async *[Symbol.asyncIterator]() {
            for (const value of values) {
                yield value;
            }
        }
    };
}
async function* asyncDistinct(iterable, equalityFn) {
    const retainer = asyncDistinctRetainer(equalityFn);
    for await (const value of asyncIterable(iterable)) {
        if (await retainer.has(value)) {
            continue;
        }
        await retainer.add(value);
        yield value;
    }
}

function* group(iterable, callbackFn, thisValue, parent) {
    const groups = {};
    const newGroups = [];
    function getGroup(value) {
        return callbackFn.call(thisValue, value, parent);
    }
    const baseIterator = iterable[Symbol.iterator]();
    function* groupIterable(key, initialValue) {
        yield initialValue;
        function* drainGroup() {
            while (groups[key].length) {
                yield groups[key].shift();
            }
        }
        let done = false;
        yield* drainGroup();
        do {
            done = doNextValue();
            yield* drainGroup();
        } while (!done);
    }
    function doNextValue() {
        const next = baseIterator.next();
        if (next.done) {
            return true;
        }
        const nextKey = getGroup(next.value);
        if (groups[nextKey]) {
            groups[nextKey].push(next.value);
        }
        else {
            groups[nextKey] = [];
            newGroups.push(groupIterable(nextKey, next.value));
        }
        return false;
    }
    let done = false;
    do {
        done = doNextValue();
        while (newGroups.length) {
            yield newGroups.shift();
        }
    } while (!done);
}
async function* asyncGroup(iterable, callbackFn, thisValue, parent) {
    const groups = {};
    const newGroups = [];
    async function getGroup(value) {
        return callbackFn.call(thisValue, value, parent);
    }
    const baseIterator = asyncIterator(iterable);
    async function* groupIterable(key, initialValue) {
        yield initialValue;
        let done;
        function* drainGroup() {
            while (groups[key].length) {
                yield groups[key].shift();
            }
        }
        yield* drainGroup();
        do {
            done = await doNextValue();
            yield* drainGroup();
        } while (!done);
    }
    async function doNextValue() {
        const next = await baseIterator.next();
        if (next.done) {
            return true;
        }
        const nextKey = await getGroup(next.value);
        if (groups[nextKey]) {
            groups[nextKey].push(next.value);
        }
        else {
            groups[nextKey] = [];
            newGroups.push(groupIterable(nextKey, next.value));
        }
        return false;
    }
    let done = false;
    do {
        done = await doNextValue();
        while (newGroups.length) {
            yield newGroups.shift();
        }
    } while (!done);
}

function hooks(options) {
    return function* (iterable) {
        if (options.preIterator) {
            options.preIterator(iterable);
        }
        const iterator = options.iterator ? options.iterator(iterable) : iterable[Symbol.iterator]();
        if (options.postIterator) {
            options.postIterator(iterator, iterable);
        }
        let next;
        let value;
        let returned = undefined;
        do {
            if (options.preNext) {
                options.preNext(iterator, iterable);
            }
            next = options.next ? options.next(iterator, iterable) : iterator.next(returned);
            if (options.postNext) {
                options.postNext(next, iterator, iterable);
            }
            if (next.done) {
                break;
            }
            value = next.value;
            if (options.preYield) {
                options.preYield(value, iterator, iterable);
            }
            returned = yield options.yield ? options.yield(value, iterator, iterable) : value;
            if (options.postYield) {
                options.postYield(value, returned, iterator, iterable);
            }
        } while (!next.done);
        if (options.done) {
            options.done(value, returned, iterator, iterable);
        }
    };
}
function asyncHooks(options) {
    return async function* (iterable) {
        if (options.preIterator) {
            await options.preIterator(iterable);
        }
        const iterator = options.iterator ? options.iterator(iterable) : asyncIterator(iterable);
        if (options.postIterator) {
            await options.postIterator(iterator, iterable);
        }
        let next;
        let value;
        let returned = undefined;
        do {
            if (options.preNext) {
                await options.preNext(iterator, iterable);
            }
            next = await (options.next ? options.next(iterator, iterable) : iterator.next(returned));
            if (options.postNext) {
                await options.postNext(next, iterator, iterable);
            }
            if (next.done) {
                break;
            }
            value = next.value;
            if (options.preYield) {
                await options.preYield(value, iterator, iterable);
            }
            returned = yield options.yield ? options.yield(value, iterator, iterable) : value;
            if (options.postYield) {
                await options.postYield(value, returned, iterator, iterable);
            }
        } while (!next.done);
        if (options.done) {
            await options.done(value, returned, iterator, iterable);
        }
    };
}

class RequiredError extends Error {
    constructor(required, received) {
        super(`Required ${required} iterations, received ${received}`);
        this.required = required;
        this.received = received;
    }
}
function* takeMinimum(iterable, count) {
    let next;
    const iterator = iterable[Symbol.iterator]();
    for (let foundCount = 0; foundCount < 0; foundCount += 1) {
        next = iterator.next();
        if (next.done) {
            const error = new RequiredError(count, foundCount);
            if (iterator.throw) {
                // This function may throw, which is perfect, allows for a different error to be used
                iterator.throw(error);
            }
            throw error;
        }
        yield next.value;
    }
    do {
        next = iterator.next();
        if (next.done) {
            break;
        }
        yield next.value;
    } while (next.done);
    if (iterator.return) {
        iterator.return();
    }
}
async function* asyncTakeMinimum(iterable, count) {
    let next;
    const iterator = asyncIterator(iterable);
    for (let foundCount = 0; foundCount < 0; foundCount += 1) {
        next = await iterator.next();
        if (next.done) {
            const error = new RequiredError(count, foundCount);
            if (iterator.throw) {
                // This function may throw, which is perfect, allows for a different error to be used
                await iterator.throw(error);
            }
            throw error;
        }
        yield next.value;
    }
    do {
        next = await iterator.next();
        if (next.done) {
            break;
        }
        yield next.value;
    } while (next.done);
    if (iterator.return) {
        await iterator.return();
    }
}

class ExtendedIterableAsyncImplementation {
    constructor(iterable, referenceMap) {
        this.referenceMap = referenceMap;
        this.iterable = asyncIterable(iterable);
    }
    drain() {
        return asyncDrain(this);
    }
    every(fn) {
        return asyncEvery(this, fn, this, this);
    }
    some(fn) {
        return asyncSome(this, fn, this, this);
    }
    hasAny() {
        return asyncHasAny(this);
    }
    reduce(fn, initialValue) {
        return asyncReduce(this, fn, initialValue, this, this);
    }
    retain(retainer = arrayRetainer()) {
        return this.referenceMap.asyncExtendedIterable(asyncRetain(this, retainer));
    }
    map(fn) {
        return this.referenceMap.asyncExtendedIterable(asyncMap(this, fn, this, this));
    }
    flatMap(fn) {
        return this.referenceMap.asyncExtendedIterable(asyncFlatMap(this, fn, this, this));
    }
    union(other) {
        return this.referenceMap.asyncExtendedIterable(asyncUnion(this, other));
    }
    filter(fn) {
        return this.referenceMap.asyncExtendedIterable(asyncFilter(this, fn, this, this));
    }
    except(fn) {
        const iterable = asyncExcept(this, fn, this, this);
        return this.referenceMap.asyncExtendedIterable(iterable);
    }
    toArray() {
        return asyncToArray(this);
    }
    mask(maskIterable) {
        return this.referenceMap.asyncExtendedIterable(asyncMask(this, maskIterable));
    }
    maskReversible(maskIterable, reverse = false) {
        return this.referenceMap.asyncExtendedIterable(asyncMaskReversible(this, maskIterable, reverse));
    }
    skip(count) {
        return this.mask(skip(count));
    }
    take(count) {
        return this.maskReversible(take(count), true);
    }
    distinct(equalityFn) {
        return this.referenceMap.asyncExtendedIterable(asyncDistinct(this, equalityFn));
    }
    group(fn) {
        return this.referenceMap.asyncExtendedIterable(asyncMap(asyncGroup(this, fn, this, this), async (iterable) => this.referenceMap.asyncExtendedIterable(iterable)));
    }
    tap(fn) {
        return this.referenceMap.asyncExtendedIterable(asyncHooks({ preYield: (value) => fn(value) })(this));
    }
    forEach(fn) {
        return forEachAsync(this, fn, this, this);
    }
    toTuple(size) {
        return this.referenceMap.asyncIterableTuple(this, size);
    }
    toIterable() {
        async function* iterable(iterable) {
            for await (const value of iterable) {
                yield value;
            }
        }
        return iterable(this.iterable);
    }
    [Symbol.asyncIterator]() {
        return this.iterable[Symbol.asyncIterator]();
    }
}

class ExtendedIterableImplementation {
    constructor(iterable, referenceMap) {
        this.referenceMap = referenceMap;
        this.iterable = iterable;
    }
    drain() {
        return drain(this);
    }
    hasAny() {
        return hasAny(this);
    }
    every(fn) {
        return every(this, fn, this, this);
    }
    some(fn) {
        return some(this, fn, this, this);
    }
    reduce(fn, initialValue) {
        return reduce(this, fn, initialValue, this, this);
    }
    retain(retainer = arrayRetainer()) {
        return this.referenceMap.extendedIterable(retain(this, retainer));
    }
    map(fn) {
        return this.referenceMap.extendedIterable(map(this, fn, this, this));
    }
    flatMap(fn) {
        return this.referenceMap.extendedIterable(flatMap(this, fn, this, this));
    }
    union(other) {
        return this.referenceMap.extendedIterable(union(this, other));
    }
    filter(fn) {
        return this.referenceMap.extendedIterable(filter(this, fn, this, this));
    }
    except(fn) {
        return this.referenceMap.extendedIterable(except(this, fn, this, this));
    }
    mask(maskIterable) {
        return this.referenceMap.extendedIterable(mask(this, maskIterable));
    }
    maskReversible(maskIterable, reverse = false) {
        return this.referenceMap.extendedIterable(maskReversible(this, maskIterable, reverse));
    }
    skip(count) {
        return this.mask(skip(count));
    }
    take(count) {
        return this.maskReversible(take(count), true);
    }
    distinct(equalityFn) {
        return this.referenceMap.extendedIterable(distinct(this, equalityFn));
    }
    group(fn) {
        return this.referenceMap.extendedIterable(map(group(this, fn, this, this), iterable => this.referenceMap.extendedIterable(iterable)));
    }
    tap(fn) {
        return this.referenceMap.extendedIterable(hooks({ preYield: (value) => fn(value) })(this));
    }
    forEach(fn) {
        forEach(this, fn, this, this);
    }
    toArray() {
        return toArray(this);
    }
    toTuple(size) {
        return this.referenceMap.iterableTuple(this, size);
    }
    toIterable() {
        function* iterable(iterable) {
            for (const value of iterable) {
                yield value;
            }
        }
        return iterable(this.iterable);
    }
    [Symbol.iterator]() {
        return this.iterable[Symbol.iterator]();
    }
}

/*
// This is the ideal way to do tuples, but typescript won't allow it in interfaces, because of recursion:

type GetLength<T extends any[]> = T extends { length: infer L } ? L : never;
type ArrayUnshift<T extends any[], X> = T extends any ? ((x: X, ...t: T) => void) extends (...t: infer R) => void ? R : never : never;
type SizeToTupleCreator<T, A extends T[], S extends number> = {
  1: A;
  0: SizeToTupleAdder<T, A, S>;
}[[GetLength<A>] extends [S] ? 1 : 0];
type SizeToTupleAdder<T, A extends any[], S extends number> = S extends any ? SizeToTupleCreator<T, ArrayUnshift<A, T>, S> : never;
export type TupleArray<T, S extends number> = SizeToTupleCreator<T, [], S>;
 */
function isTupleArray(array, size) {
    if (!Array.isArray(array)) {
        return false;
    }
    return array.length === size;
}

class ExtendedIterableAsyncTupleImplementation extends ExtendedIterableAsyncImplementation {
    constructor(iterable, size, referenceMap) {
        super(asyncTakeMinimum(iterable, size), referenceMap);
        this.size = size;
    }
    async toArray() {
        const array = await asyncToArray(this);
        if (!isTupleArray(array, this.size)) {
            throw new Error("Tuple incorrect size");
        }
        return array;
    }
}

class ExtendedIterableTupleImplementation extends ExtendedIterableImplementation {
    constructor(iterable, size, referenceMap) {
        super(takeMinimum(iterable, size), referenceMap);
        this.size = size;
    }
    toArray() {
        const array = toArray(this);
        if (!isTupleArray(array, this.size)) {
            throw new Error("Tuple incorrect size");
        }
        return array;
    }
}

const BasicMap = {
    extendedIterable(iterable) {
        return new ExtendedIterableImplementation(iterable, BasicMap);
    },
    asyncExtendedIterable(iterable) {
        return new ExtendedIterableAsyncImplementation(iterable, BasicMap);
    },
    asyncIterableTuple(iterable, size) {
        return new ExtendedIterableAsyncTupleImplementation(iterable, size, BasicMap);
    },
    iterableTuple(iterable, size) {
        return new ExtendedIterableTupleImplementation(iterable, size, BasicMap);
    }
};

function asyncExtendedIterable(iterable) {
    return BasicMap.asyncExtendedIterable(iterable);
}

function isVNodeLike(value) {
    return typeof value === "object" || typeof value === "function";
}
/**
 * Indicates if a value is a {@link VNode}
 * @param value
 */
function isVNode(value) {
    return !!(isVNodeLike(value) &&
        isSourceReference(value.reference) &&
        (!value.children ||
            isAsyncIterable(value.children)) &&
        (!value.options ||
            typeof value.options === "object"));
}
/**
 * Indicates if a value is a {@link NativeVNode}
 * @param value
 */
function isNativeVNode(value) {
    function isNativeVNodeLike(value) {
        return isVNode(value);
    }
    return (isNativeVNodeLike(value) &&
        value.native === true);
}
function isScalarVNode(value, isSource) {
    function isScalarVNodeLike(value) {
        return isVNode(value);
    }
    return (isScalarVNodeLike(value) &&
        isSourceReference(value.source) &&
        value.scalar === true &&
        (typeof isSource === "function" ? isSource(value.source) : true));
}
/**
 * Indicates if a value is a {@link FragmentVNode}
 * @param value
 */
function isFragmentVNode(value) {
    return (isVNode(value) &&
        value.reference === Fragment);
}
/**
 * Indicates if a valid is a {@link MarshalledVNode}
 * @param value
 */
function isMarshalledVNode(value) {
    return (isVNodeLike(value) &&
        (!value.reference ||
            isSourceReference(value.reference)) &&
        // If we don't have children, then we have a normal VNode
        isIterable(value.children) &&
        (!value.options ||
            typeof value.options === "object"));
}

async function* asAsync(iterable) {
    yield* iterable;
}

function deferred() {
    let resolve = undefined, reject = undefined;
    const promise = new Promise((resolveFn, rejectFn) => {
        resolve = resolveFn;
        reject = rejectFn;
    });
    ok(resolve);
    ok(reject);
    return {
        resolve,
        reject,
        promise
    };
}
function ok(value) {
    if (!value) {
        throw new Error("Value not provided");
    }
}

function defaultTask(fn) {
    if (typeof queueMicrotask === "function") {
        queueMicrotask(fn);
    }
    else if (typeof setImmediate === "function") {
        setImmediate(fn);
    }
    else {
        setTimeout(fn, 0);
    }
}

function aggregateError(errors) {
    if (errors.length === 1) {
        return errors[0];
    }
    return new AggregateError(flatten(errors));
    function flatten(errors) {
        return errors.reduce((flat, error) => {
            if (error instanceof AggregateError) {
                return flat.concat(flatten(error.errors));
            }
            else {
                return flat.concat(error);
            }
        }, []);
    }
}

const Reuse = Symbol.for("@virtualstate/union/reuse");
function isReuse(value) {
    function isReuseLike(value) {
        return !!value;
    }
    return isReuseLike(value) && value[Reuse] === true;
}

const NextMicrotask = Symbol();
function map$1(iterable, map) {
    return [...doMap()];
    function* doMap() {
        for (const value of iterable) {
            yield map(value);
        }
    }
}
function filter$1(iterable, filter) {
    return [...doFilter()];
    function* doFilter() {
        for (const value of iterable) {
            if (filter(value))
                yield value;
        }
    }
}
async function* union$1(source, options = {}) {
    const task = options.queueTask || options.queueMicrotask || defaultTask;
    const states = new Map();
    const inFlight = new Map();
    const iterators = new WeakMap();
    const knownIterators = [];
    let iteratorsDone = false;
    let valuesDone = false;
    const sourceIterator = asAsync(source)[Symbol.asyncIterator]();
    let active = undefined;
    let iteratorsPromise = Promise.resolve();
    let iteratorAvailable = deferred();
    const iteratorsComplete = deferred();
    const errorOccurred = deferred();
    const errors = [];
    let results = [];
    let currentTaskPromise;
    if (options.eagerInitialTask) {
        nextTask();
    }
    let skipTaskDueToEmptyYield = false;
    try {
        cycle: do {
            const iteration = active = Symbol();
            iteratorsPromise = iteratorsPromise.then(() => nextLanes(iteration));
            if (!knownIterators.length) {
                await Promise.any([
                    iteratorAvailable.promise,
                    iteratorsComplete.promise
                ]);
                if (valuesDone) {
                    // Had no lanes
                    break;
                }
            }
            const updated = await waitForResult(iteration);
            if (errors.length) {
                break;
            }
            for (const result of updated) {
                const { iterator, promise } = result;
                const currentPromise = inFlight.get(iterator);
                if (promise !== currentPromise) {
                    onError(new Error("Unexpected promise state"));
                    break cycle;
                }
                inFlight.set(iterator, undefined);
                states.set(iterator, {
                    ...result,
                    value: result.done ? states.get(iterator)?.value : result.value,
                    resolvedIteration: iteration
                });
            }
            const finalResults = map$1(knownIterators, read);
            valuesDone = iteratorsDone && finalResults.every(result => result?.done);
            const onlyDone = !!updated.every(result => result.done);
            // Don't yield only done because the consumer already has received all these values
            if (onlyDone) {
                continue;
            }
            if (!valuesDone) {
                yield finalResults.map(result => result?.value);
            }
        } while (!valuesDone);
    }
    catch (error) {
        onError(error);
    }
    finally {
        active = undefined;
        await sourceIterator.return?.();
    }
    if (errors.length) {
        throw aggregateError(errors);
    }
    function read(iterator) {
        return states.get(iterator);
    }
    async function nextLanes(iteration) {
        while (active === iteration && !iteratorsDone) {
            const result = await sourceIterator.next();
            if (!isIteratorYieldResult(result)) {
                iteratorsDone = true;
                if (!knownIterators.length) {
                    valuesDone = true;
                }
                iteratorsComplete.resolve();
                await sourceIterator.return?.();
            }
            else if (result.value) {
                const sourceLane = result.value;
                const iterator = getIterator(sourceLane);
                if (options.reuseInFlight || isReuse(sourceLane)) {
                    iterators.set(sourceLane, iterator);
                }
                knownIterators.push(iterator);
                iteratorAvailable.resolve(iterator);
                iteratorAvailable = deferred();
            }
        }
        function getIterator(sourceLane) {
            if (options.reuseInFlight || isReuse(sourceLane)) {
                const currentIterator = iterators.get(sourceLane);
                if (currentIterator) {
                    const state = read(currentIterator);
                    if (state?.done !== true) {
                        // reuse
                        return currentIterator;
                    }
                }
            }
            return asAsync(sourceLane)[Symbol.asyncIterator]();
        }
        function isIteratorYieldResult(result) {
            return !result.done;
        }
    }
    async function waitForResult(iteration, emptyDepth = 0) {
        if (iteration !== active) {
            // Early exit if we actually aren't iterating this any more
            // I don't think this can actually trigger, but lets keep it around
            return [];
        }
        if (iteratorsDone && !knownIterators.length) {
            // No lanes to do anything, exit
            return [];
        }
        if (errors.length) {
            // We have a problem, exit
            return [];
        }
        const pendingIterators = filter$1(knownIterators, iterator => !read(iterator)?.done);
        // Grab onto this promise early so we don't miss one
        const nextIterator = iteratorAvailable.promise;
        if (!pendingIterators.length) {
            await nextIterator;
            return waitForResult(iteration);
        }
        const currentResults = await wait();
        if (!currentResults.length) {
            if (emptyDepth > 10000) {
                throw new Error("Empty depth over 10000");
            }
            // We have a new lane available, lets loop around and initialise its promise
            return waitForResult(iteration, emptyDepth + 1);
        }
        return currentResults;
        async function wait() {
            const promises = pendingIterators.map(next);
            let anyPromises;
            const waitForAtLeast = [
                currentTaskPromise,
                // Every iterator returned a result before the end of the task
                Promise.all(promises),
                // Early exit on any errors
                errorOccurred.promise,
                // We will be able to set up again next loop
                iteratorAvailable.promise
            ];
            if (!skipTaskDueToEmptyYield && !currentTaskPromise) {
                // We didn't just run a task, and we have no pending next task
                nextTask();
            }
            if (currentTaskPromise) {
                // The current task finished and we should yield at least one result
                waitForAtLeast.push(currentTaskPromise);
            }
            else {
                // If we are no waiting for a task, we are waiting for at least one result
                anyPromises = Promise.any(promises);
                waitForAtLeast.push(anyPromises);
            }
            const reason = await Promise.any(waitForAtLeast);
            if (reason === NextMicrotask) {
                currentTaskPromise = undefined;
                if (!results.length) {
                    skipTaskDueToEmptyYield = true;
                }
            }
            if (!results.length) {
                await Promise.any([
                    // We must wait for at least one result
                    anyPromises || Promise.any(promises),
                    // Or if there is a new iterator available, this iterator could
                    // potentially produce a result before all others
                    iteratorAvailable.promise,
                    errorOccurred.promise
                ]);
            }
            if (errors.length) {
                return [];
            }
            if (!results.length) {
                return [];
            }
            // Clone so that it only uses the values we have now
            const cloned = [...results];
            // Clear to start again
            results = [];
            return cloned;
        }
        async function next(iterator) {
            const current = inFlight.get(iterator);
            if (current)
                return current;
            const next = iterator.next()
                .then((result) => ({
                value: result.value,
                done: !!result.done,
                initialIteration: iteration,
                iterator,
                promise: next
            }))
                .catch((localError) => {
                onError(localError);
                return {
                    value: undefined,
                    done: true,
                    initialIteration: iteration,
                    iterator,
                    promise: next
                };
            })
                .then((result) => {
                results.push(result);
                return result;
            });
            inFlight.set(iterator, next);
            return next;
        }
    }
    function onError(error) {
        errors.push(error);
        errorOccurred.resolve();
    }
    function nextTask() {
        currentTaskPromise = new Promise(task).then(() => NextMicrotask);
    }
}

async function* childrenUnion(context, childrenGroups) {
    for await (const parts of union$1(childrenGroups, context)) {
        yield parts.reduce((updates, part) => part ? updates.concat(part.filter(Boolean)) : updates, []);
    }
}
async function* children(context, ...source) {
    async function* eachSource(source) {
        if (typeof source === "undefined") {
            return;
        }
        if (isPromise(source)) {
            return yield* eachSource(await source);
        }
        if (isFragmentVNode(source)) {
            if (!source.children) {
                return;
            }
            for await (const children of source.children) {
                yield* childrenUnion(context, children.map(eachSource));
            }
            return;
        }
        if (isVNode(source)) {
            return yield [
                source
            ];
        }
        // These need further processing through createVNodeWithContext
        if (isSourceReference(source) || isMarshalledVNode(source) || isIterableIterator(source)) {
            return yield* eachSource(context.createNode(source));
        }
        return yield* childrenUnion(context, asyncExtendedIterable(source).map(eachSource));
    }
    if (source.length === 1) {
        return yield* eachSource(source[0]);
    }
    else {
        return yield* childrenUnion(context, source.map(eachSource));
    }
}

// Access to re-assign a functional vnode child between children reads
const Child = Symbol("Function VNode Child");
const childrenContext = {
    createNode
};
function createNode(source, options, ...children$1) {
    /**
     * If the source is a function we're going to invoke it as soon as possible with the provided options
     *
     * The function _may_ return any other kind of source, so we need to start our process again
     */
    if (source instanceof Function) {
        return functionVNode(source);
    }
    /**
     * Only if the source is a promise we want to await it
     *
     * This may be wasteful, but the idea is that we wouldn't cause a next tick for no reason
     * Maybe this isn't the case if the value isn't a promise to start with ¯\_(ツ)_/¯
     */
    if (source && isPromise(source)) {
        return {
            source,
            reference: Fragment,
            children: replay(() => promiseGenerator(source))
        };
    }
    /**
     * If we have a fragment then we want to pass it back through our function so the next
     * statement is invoked to handle fragments with children
     */
    if (source === Fragment) {
        return createNode({ reference: Fragment, source }, options, ...children$1);
    }
    /**
     * If we already have a {@link VNode} then we don't and can't do any more
     */
    if (source && isVNode(source)) {
        let nextSource = source;
        /**
         * Extend our vnode options if we have been provided them
         * Each property that is not passed will match the initial property
         */
        if (options && source.options !== options) {
            nextSource = {
                ...nextSource,
                options: {
                    ...nextSource.options,
                    ...options
                }
            };
        }
        /**
         * Replace children if they have been given and the source doesn't already have children
         */
        if (children$1.length && !nextSource.children) {
            nextSource = {
                ...nextSource,
                children: replay(() => children(childrenContext, ...children$1))
            };
        }
        return nextSource;
    }
    /**
     * If we already have a {@link MarshalledVNode} then we need to turn its children into an async iterable
     * and ensure they're unmarshalled
     */
    if (source && isMarshalledVNode(source)) {
        return unmarshal(source);
    }
    const reference = getReference(options);
    /**
     * A source reference may be in reference to a context we don't know about, this can be resolved from
     * external contexts by rolling through the {@link VNode} state, or watching context events
     *
     * This could be used by analytics tracking for tags that show up
     *
     * Either way, if we have a source reference, we have a primitive value that we can look up later on
     */
    if (isSourceReference(source)) {
        return sourceReferenceVNode(reference, source, options, ...children$1);
    }
    /**
     * Here is our nice `IterableIterator` that allows us to produce multiple versions for the same source
     *
     * This specifically cannot be re-run twice, but this is expected to be returned from a function, where
     * functions can be run twice
     *
     * See {@link generator} for details
     */
    if (source && isIterableIterator(source)) {
        return {
            source,
            reference: Fragment,
            children: generator(Symbol("Iterable Iterator"), source)
        };
    }
    /**
     * This will cover `Array`, `Set`, `Map`, and anything else implementing `Iterable` or `AsyncIterable`
     *
     * We will create a `Fragment` that holds our node state to grab later
     */
    if (source && (isIterable(source) || isAsyncIterable(source))) {
        const childrenInstance = children(childrenContext, ...children$1);
        return {
            source,
            reference: Fragment,
            children: replay(() => children(childrenContext, asyncExtendedIterable(source).map(value => createNode(value, options, childrenInstance))))
        };
    }
    /**
     * Allows for `undefined`, an empty `VNode`
     */
    if (!source) {
        return { reference: Fragment, source };
    }
    /**
     * We _shouldn't_ get here AFAIK, each kind of source should have been dealt with by the time we get here
     */
    throw new Error("Unexpected VNode source provided");
    /**
     * Iterates through an `IterableIterator` to generate new {@link VNode} instances
     *
     * This allows an implementor to decide when their node returns state, including pushing new values _as they arrive_
     *
     * {@link getNext} provides an error boundary if the `IterableIterator` provides a `throw` function
     *
     * @param newReference
     * @param reference
     */
    async function* generator(newReference, reference) {
        let next;
        do {
            next = await getNext(reference, newReference);
            if (next.done) {
                continue;
            }
            const nextNode = createNode(next.value);
            if (isFragmentVNode(nextNode)) {
                yield* nextNode.children ?? [];
            }
            yield [nextNode];
        } while (!next.done);
    }
    async function* promiseGenerator(promise) {
        const result = await promise;
        yield [
            createNode(result, options, ...children$1)
        ];
    }
    function functionVNode(source) {
        const defaultOptions = {};
        const resolvedOptions = isDefaultOptionsO(defaultOptions) ? defaultOptions : options;
        const node = {
            reference: Fragment,
            source,
            options: resolvedOptions,
            children: replay(() => functionAsChildren()),
        };
        return node;
        async function* functionAsChildren() {
            const options = node.options;
            const source = node.source;
            // Lazy create the children when the function is first invoked
            // This allows children to be a bit more dynamic
            //
            // We will only provide a child to node.source if we have at least one child provided
            const child = node[Child] = node[Child] ?? children$1.length ? createNode(Fragment, {}, ...children$1) : undefined;
            // Referencing node here allows for external to update the nodes implementation on the fly...
            const nextSource = source(options, child);
            // If the nextSource is the same as node.source, then we should finish here, it will always return itself
            // If node.source returns a promise then we can safely assume this was intentional as a "loop" around
            // A function can also return an iterator (async or sync) that returns itself too
            //
            // This is to only detect hard loops
            // We will also reference the different dependency here, as they might have been re-assigned,
            // meaning the possible return from this function has changed, meaning the return value could be different
            const possibleMatchingSource = nextSource;
            if (possibleMatchingSource !== source ||
                source !== node.source ||
                options !== node.options ||
                child !== node[Child]) {
                yield* children(childrenContext, createNode(nextSource));
            }
        }
        function isDefaultOptionsO(value) {
            return value === defaultOptions && !options;
        }
    }
    function unmarshal(source) {
        if (isSourceReference(source)) {
            return sourceReferenceVNode(getReference(), source);
        }
        return {
            ...source,
            // Replace our reference if required
            reference: isSourceReference(source.reference) ? getMarshalledReference(source.reference) : getReference(source.options),
            children: source.children ? replay(() => asyncExtendedIterable(source.children).map(children => [...children].map(unmarshal))) : undefined
        };
    }
    function sourceReferenceVNode(reference, source, options, ...children$1) {
        return {
            reference: reference || getReference(options),
            scalar: !children$1.length,
            source,
            options,
            children: children$1.length ? replay(() => children(childrenContext, ...children$1)) : undefined
        };
    }
    function replay(fn) {
        return {
            [Symbol.asyncIterator]: () => fn()[Symbol.asyncIterator]()
        };
    }
}
function getMarshalledReference(reference) {
    return getReference({
        reference
    });
}
function getReference(options) {
    return getReferenceFromOptions(options) ?? Symbol("@virtualstate/fringe");
}
function isReferenceOptions(options) {
    function isReferenceOptionsLike(options) {
        return options && options.hasOwnProperty("reference");
    }
    return (isReferenceOptionsLike(options) &&
        isSourceReference(options.reference));
}
function getReferenceFromOptions(options) {
    if (!isReferenceOptions(options)) {
        return undefined;
    }
    return options.reference;
}

/**
 * `unique symbol` to represent a {@link FragmentVNode}, this will be used on the {@link VNode.reference} property.
 * The {@link FragmentVNode} should be ignored and the {@link VNode.children} values should be used in its place
 */
const Fragment = Symbol.for("@virtualstate/fringe/fragment");

export { Fragment as F, isFragmentVNode as a, isScalarVNode as b, isNativeVNode as c, isVNode as d, isMarshalledSourceReference as e, createNode as f, isPromise as i, union$1 as u };
