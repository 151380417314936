import { i as isPromise, a as isFragmentVNode, b as isScalarVNode, c as isNativeVNode$1, F as Fragment, u as union, d as isVNode, e as isMarshalledSourceReference, f as createNode } from '../common/fragment-7069be2b.js';

function deferred() {
    let resolve = undefined, reject = undefined;
    const promise = new Promise((resolveFn, rejectFn) => {
        resolve = resolveFn;
        reject = rejectFn;
    });
    ok(resolve);
    ok(reject);
    return {
        resolve,
        reject,
        promise
    };
}
function ok(value) {
    if (!value) {
        throw new Error("Value not provided");
    }
}

function insertMany(list, afterIndex, many) {
    const entryArray = Array.from(many.entries());
    entryArray.forEach(([index, value], arrayIndex, array) => {
        const before = array[arrayIndex - 1];
        list.insert(before ? before[0] : afterIndex, index, value);
    });
}
class AbstractLinkedList {
    constructor(map, initial) {
        this.map = map;
        if (initial) {
            insertMany(this, undefined, initial);
        }
    }
    setMap(map) {
        this.map = map;
    }
    get(pointer) {
        return this.map.get(pointer);
    }
    set(pointer, value) {
        const node = this.get(pointer);
        if (node) {
            this.map.set(pointer, {
                ...node,
                value
            });
        }
        else {
            throw new Error("Pointer does not belong in this list");
        }
    }
    insert(after, pointer, value) {
        if (!after) {
            this.clear();
        }
        const reference = after && this.get(after);
        if (after && !reference) {
            throw new Error("Pointer does not belong in this list");
        }
        this.map.set(pointer, {
            value,
            next: reference ? reference.next : undefined
        });
        if (after) {
            this.map.set(after, {
                ...reference,
                next: pointer
            });
        }
    }
}

class WeakLinkedList extends AbstractLinkedList {
    constructor(initial) {
        super(new WeakMap(), initial);
    }
    clear() {
        this.setMap(new WeakMap());
    }
}

function defaultQueueMicrotask(fn) {
    if (typeof queueMicrotask === "function") {
        queueMicrotask(fn);
    }
    else if (typeof setImmediate === "function") {
        setImmediate(fn);
    }
    else {
        setTimeout(fn, 0);
    }
}
class InternalAbortError extends Error {
}
function noop() {
}
const START = Symbol("Start");
const END = Symbol("End");
const YIELD = Symbol("Yield");
class Collector {
    constructor(options = {}) {
        this.#active = true;
        this.#resolve = undefined;
        this.#promise = undefined;
        this.#rejection = deferred();
        this.#values = new WeakLinkedList();
        this.#pointer = {};
        this.#eagerPointer = undefined;
        this.#add = (value, queue = true) => {
            const pointer = this.#pointer;
            const node = this.#values.get(pointer);
            if (!node) {
                // Start things off
                this.#values.insert(undefined, pointer, START);
                return this.#add(value, queue);
            }
            const next = {};
            this.#values.insert(pointer, next, value);
            this.#pointer = next;
            if (queue) {
                this.#queueResolve();
            }
        };
        this.#constructPromise = () => {
            const defer = deferred();
            this.#promise = defer.promise;
            this.#resolve = defer.resolve;
            // Prevent uncaught exception
            this.#promise.catch(noop);
            // Pass on the rejection to our individual promise
            // If our promise is already resolved then this will have no effect
            this.#rejection.promise.catch(defer.reject);
        };
        this.#queueResolve = () => {
            if (!this.#resolve) {
                this.#constructPromise();
            }
            const resolve = this.#resolve;
            const promise = this.#promise;
            this.#resolve = undefined;
            this.queueMicrotask(() => {
                if (this.#promise === promise) {
                    this.#promise = undefined;
                }
                this.#add(YIELD, false);
                resolve();
            });
        };
        this.queueMicrotask = options.queueMicrotask || defaultQueueMicrotask;
        if (options.eagerCollection) {
            this.#eagerPointer = this.#pointer;
        }
        this.#yieldGroups = options.yieldGroups ?? false;
        // Catch early so if there is no iterators being utilised the process won't crash!
        this.#rejection.promise.catch(noop);
    }
    #active;
    #resolve;
    #promise;
    #rejection;
    #values;
    #pointer;
    #eagerPointer;
    #yieldGroups;
    add(value) {
        if (!this.#active)
            return;
        this.#add(value, true);
    }
    #add;
    #constructPromise;
    #queueResolve;
    close() {
        this.#active = false;
        this.#add(END);
        this.#rejection.reject(new InternalAbortError());
    }
    async *[Symbol.asyncIterator]() {
        if (!this.#active)
            return;
        const values = this.#values;
        const yieldGroups = this.#yieldGroups;
        let pointer = this.#pointer;
        // This will allow the first iterator to see all available values, rather than
        // the ones starting from the current final pointer of the linked list
        if (this.#eagerPointer) {
            pointer = this.#eagerPointer;
            // Clearing in the next microtask means any iterators added within this microtask will
            // get the eager pointer, where the next microtask this pointer will be gone
            this.queueMicrotask(() => this.#eagerPointer = undefined);
        }
        let promise = undefined, yielded = new WeakSet();
        try {
            do {
                let yielding;
                while ((yielding = compile()).length) {
                    yield yielding;
                }
                if (!this.#promise) {
                    this.#constructPromise();
                }
                promise = this.#promise;
                try {
                    // Its important to await here so we locally catch
                    // Once the promise has resolved we will hit our eager loop and yield the
                    // available values
                    await promise;
                }
                catch (error) {
                    if (isInternalAbortError(error)) {
                        break;
                    }
                    else {
                        yield Promise.reject(error);
                    }
                }
            } while (this.#active);
        }
        finally {
            const remaining = compile();
            if (remaining.length) {
                yield remaining;
            }
        }
        /**
         * Will compile an array of all values not yet yielded
         * All values returned will not be returned again by this iterator
         */
        function compile() {
            const array = [];
            let node = values.get(pointer);
            while (node = values.get(pointer)) {
                const value = node.value;
                if (!yielded.has(pointer)) {
                    yielded.add(pointer);
                    if (value === END)
                        break;
                    if (value === START)
                        continue;
                    // This will allow different iterators to get the same looking yield groups, excluding
                    // where values are available early
                    if (value === YIELD) {
                        if (!yieldGroups) {
                            continue;
                        }
                        if (array.length) {
                            break;
                        }
                        else {
                            // If we have no values to yield, move to the next yield group
                            continue;
                        }
                    }
                    array.push(value);
                }
                if (!node.next) {
                    break;
                }
                pointer = node.next;
            }
            return array;
        }
    }
}
function isInternalAbortError(error) {
    if (error instanceof InternalAbortError) {
        return true;
    }
    if (error instanceof AggregateError) {
        for (const aggregateError of error.errors) {
            if (isInternalAbortError(aggregateError)) {
                return true;
            }
        }
    }
    return false;
}

function createVContextEvents() {
    const target = {
        createNode: new Collector({
            eagerCollection: true
        }),
        children: new Collector({
            eagerCollection: true
        }),
        hydrate: new Collector({
            eagerCollection: true
        })
    };
    return {
        target,
        events: target
    };
}

class WeakVContext {
    constructor(weak, { events, target } = createVContextEvents()) {
        this.weak = weak || new WeakMap();
        this.events = events;
        this.eventsTarget = target;
    }
    hydrate(node, tree) {
        const event = {
            node,
            tree
        };
        assertHydrateEvent(event);
        this.eventsTarget.hydrate.add(event);
        return Promise.resolve();
        function assertHydrateEvent(event) {
            function isHydrateEventLike(event) {
                return !!event;
            }
            if (!(isHydrateEventLike(event) && event.node === node && event.tree === tree)) {
                throw new Error("Expected HydrateEvent");
            }
        }
    }
    close() {
        this.eventsTarget.children.close();
        this.eventsTarget.hydrate.close();
        this.eventsTarget.createNode.close();
        return Promise.resolve();
    }
}

/**
 * Hydrates a group of children obtained from {@link VNode.children}
 *
 * Children are hydrated in parallel, this means we aren't blocking sibling children from hydrating
 *
 * @param context
 * @param node
 * @param tree
 * @param children
 */
async function hydrateChildrenGroup(context, node, tree, children) {
    /**
     * Create a tree so that hydrators can "figure out" where they are
     *
     * We want this information to be as simple as possible, which means only
     * recording the references being used
     * rather than passing vnode references around
     *
     * We want those vnodes to be as weakly referenced as possible because
     * they're just a state snapshot
     */
    const nextTree = Object.freeze({
        children: Object.freeze(children.map(child => child ? child.reference : undefined)),
        parent: tree,
        reference: node.reference
    });
    /**
     * Wait for all children to hydrate
     */
    await Promise.all(children.map(hydrateChild));
    async function hydrateChild(child) {
        try {
            await hydrate(context, child, nextTree);
        }
        catch (error) {
            if (context.catch) {
                await context.catch(error, child, nextTree);
            }
            else {
                throw error;
            }
        }
    }
}
/**
 * This will continue until there are no more generated children for the given {@link VNode}
 *
 * This allows values to be hydrated every time there is a new group of children instances
 *
 * At a top level this means that if we still have children being generated, we're still
 * going to be waiting for it to complete, if you need only one group of children to be hydrated then
 * use {@link hydrateChildrenGroup}
 *
 * @param context
 * @param node
 * @param tree
 */
async function hydrateChildren(context, node, tree) {
    if (!node.children) {
        return;
    }
    try {
        for await (const nextChildren of node.children) {
            await hydrateChildrenGroup(context, node, tree, nextChildren);
        }
    }
    catch (error) {
        if (context.catch) {
            await context.catch(error, node, tree);
        }
        else {
            throw error;
        }
    }
}
/**
 * If available, invokes {@link VContext.hydrate} with the given {@link VNode} and {@link Tree}
 *
 * The {@link VContext} is expected to hydrate the associated {@link VNode.children} when required
 *
 * @param context
 * @param node
 * @param tree
 */
async function hydrate(context, node, tree) {
    if (!context.hydrate || !node) {
        return;
    }
    try {
        return await context.hydrate(node, tree);
    }
    catch (error) {
        if (context.catch) {
            await context.catch(error, node, tree);
        }
        else {
            throw error;
        }
    }
}

function isNode(value) {
    function isNodeLike(value) {
        return !!value;
    }
    return (isNodeLike(value) &&
        typeof value.nodeType === "number" &&
        typeof value.TEXT_NODE === "number" &&
        typeof value.ELEMENT_NODE === "number");
}
function isText(node) {
    return isNode(node) && typeof node.nodeType === "number" && node.nodeType === node.TEXT_NODE;
}
function isElement(node) {
    return isNode(node) && typeof node.nodeType === "number" && node.nodeType === node.ELEMENT_NODE;
}
function assertElement(node) {
    if (!isElement(node)) {
        throw new Error("Expected Element");
    }
}
function assertType(value) {
    const type = value.options.type;
    if (!(type === "Element" || type === "Text" || type === "Node")) {
        throw new Error(`Expected Element, Node, or Text, received ${type}`);
    }
}
function isExpectedNode(expected, given) {
    if (!given) {
        return false;
    }
    assertType(expected);
    if (expected.options.type === "Text") {
        return isText(given);
    }
    if (expected.options.type === "Node") {
        return isNode(given);
    }
    if (expected.options.type === "Element") {
        return isElement(given) && expected.source === given.localName;
    }
    return false;
}
async function getDocumentNode(root, node) {
    assertType(node);
    if (typeof node.options.getDocumentNode === "function") {
        let result = node.options.getDocumentNode(root, node);
        if (isPromise(result)) {
            result = await result;
        }
        if (result) {
            if (!isExpectedNode(node, result)) {
                if (node.options.type === "Text") {
                    throw new Error(`Expected getDocumentNode to return a Text node`);
                }
                else {
                    throw new Error(`Expected getDocumentNode to return an Element node with the localName ${node.source}, but didn't receive this`);
                }
            }
            return result;
        }
    }
    if (node.options.type === "Text") {
        if (isText(node.options.instance)) {
            return node.options.instance;
        }
        return root.ownerDocument.createTextNode(node.source);
    }
    if (isNode(node.options.instance)) {
        return node.options.instance;
    }
    if (node.options.whenDefined && root.ownerDocument.defaultView.customElements && root.ownerDocument.defaultView.customElements.whenDefined) {
        await root.ownerDocument.defaultView.customElements.whenDefined(node.source);
    }
    return root.ownerDocument.createElement(node.source, { is: node.options.is });
}

function isNativeAttributeValue(value) {
    return (value === undefined ||
        typeof value === "string" ||
        typeof value === "boolean" ||
        typeof value === "number");
}
function isNativeAttributesObject(attributes) {
    if (!attributes) {
        return false;
    }
    const invalidIndex = Object.keys(attributes).findIndex(key => !isNativeAttributeValue(attributes[key]));
    return invalidIndex === -1;
}
function isAttributesOptions(options) {
    function isAttributesLike(options) {
        return !!options;
    }
    return (isAttributesLike(options) &&
        typeof options.attributes === "object" &&
        isNativeAttributesObject(options.attributes));
}
function isOnBeforeRenderOptions(options) {
    function isOnBeforeRenderLike(options) {
        return !!options;
    }
    return (isOnBeforeRenderLike(options) &&
        typeof options.onBeforeRender === "function");
}
function isGetDocumentNodeOptions(options) {
    function isGetDocumentNodeLike(options) {
        return !!options;
    }
    return (isGetDocumentNodeLike(options) &&
        typeof options.getDocumentNode === "function");
}
function isNativeOptions(options) {
    function isNativeOptionsLike(options) {
        return !!options;
    }
    function isAttributesOptionsLike(options) {
        return !!(!options.attributes ||
            isAttributesOptions(options));
    }
    function isOnBeforeRenderOptionsLike(options) {
        return !!(!options.onBeforeRender ||
            isOnBeforeRenderOptions(options));
    }
    function isGetDocumentNodeOptionsLike(options) {
        return !!(!options.getDocumentNode ||
            isGetDocumentNodeOptions(options));
    }
    function isIsOptionsLike(options) {
        return !!(options.is === undefined ||
            isIsOptions(options));
    }
    function isInstanceOptionsLike(options) {
        return !!(options.instance === undefined ||
            isElement(options.instance) ||
            isText(options.instance));
    }
    function isWhenDefinedOptionsLike(options) {
        return !!(typeof options.whenDefined === "boolean" ||
            options.whenDefined === undefined);
    }
    return !!(isNativeOptionsLike(options) &&
        (options.type === "Element" ||
            options.type === "Text" ||
            options.type === "Node") &&
        isAttributesOptionsLike(options) &&
        isOnBeforeRenderOptionsLike(options) &&
        isGetDocumentNodeOptionsLike(options) &&
        isIsOptionsLike(options) &&
        isInstanceOptionsLike(options) &&
        isWhenDefinedOptionsLike(options));
}
function getNativeOptions(vnode) {
    if (isFragmentVNode(vnode)) {
        return undefined;
    }
    if (isTypeOptions(vnode.options, "Node")) {
        return vnode.options;
    }
    if (isTypeOptions(vnode.options, "Text")) {
        return vnode.options;
    }
    // If we have no given options, then we have a text node
    if (isScalarVNode(vnode) && !vnode.options && typeof vnode.source !== "symbol") {
        return {
            ...vnode.options,
            type: "Text"
        };
    }
    // We can only create elements from string sources
    if (typeof vnode.source !== "string") {
        return undefined;
    }
    if (isTypeOptions(vnode.options, "Element") && isIsOptions(vnode.options)) {
        return vnode.options;
    }
    else {
        return {
            ...vnode.options,
            type: "Element",
            is: undefined
        };
    }
}
function isIsOptions(options) {
    function isIsOptionsLike(options) {
        return !!options;
    }
    return (isIsOptionsLike(options) &&
        (typeof options.is === "string" ||
            typeof options.is === "undefined"));
}
function isTypeOptions(options, type) {
    function isTypeOptionsLike(options) {
        return !!options;
    }
    return (isTypeOptionsLike(options) &&
        options.type === type);
}

const FragmentDOMNativeVNodeSymbol = Symbol("Fragment DOM Native VNode");
function createFragment(node) {
    const fragment = {
        ...node,
        children: children(node),
        reference: Fragment,
        options: node.options,
        native: true,
        [FragmentDOMNativeVNodeSymbol]: true
    };
    assertFragmentDOMNativeVNode(fragment);
    return fragment;
}
function isFragmentDOMNativeVNode(node) {
    function isFragmentDOMNativeVNodeLike(node) {
        return isFragmentVNode(node);
    }
    return (isFragmentDOMNativeVNodeLike(node) &&
        node[FragmentDOMNativeVNodeSymbol] === true &&
        isNativeVNode$1(node));
}
function assertFragmentDOMNativeVNode(node) {
    if (!isFragmentDOMNativeVNode(node)) {
        throw new Error("Expected FragmentDOMNativeVNode");
    }
}

function withOptions(options, input) {
    return (node) => input(options, node);
}

function children(node) {
    return {
        async *[Symbol.asyncIterator]() {
            yield* childrenGenerator();
        }
    };
    async function* childrenGenerator() {
        if (!node.children)
            return;
        for await (const children of node.children) {
            if (!children.length) {
                continue;
            }
            if (children.every(isDOMNativeVNode)) {
                yield [...children];
                continue;
            }
            // We have a bunch of iterables, async or not, that will provide an array of
            // ElementDOMNativeVNode for each iteration
            const lanes = children
                .map(withOptions({}, Native))
                .map(elementChildren);
            const merged = union(lanes);
            for await (const parts of merged) {
                yield parts.reduce((updates, part) => updates.concat(part ?? []), []);
            }
        }
    }
    function elementChildren(node) {
        return isFragmentDOMNativeVNode(node) ? node.children : [[node]];
    }
}

const DOMNativeVNodeSymbol = Symbol("DOM Native VNode");
function createVNode(node) {
    const native = {
        ...node,
        source: String(node.source),
        reference: node.reference || Symbol("@virtualstate/dom/native"),
        native: true,
        // We're going to git these children a few times, so we want to retain our values
        children: children(node),
        options: node.options,
        [DOMNativeVNodeSymbol]: true
    };
    assertDOMNativeVNode(native);
    return native;
}
function isDOMNativeVNode(node) {
    function isDOMNativeNodeLike(node) {
        return isVNode(node);
    }
    return (isDOMNativeNodeLike(node) &&
        node[DOMNativeVNodeSymbol] === true &&
        isNativeVNode$1(node) &&
        typeof node.source === "string" &&
        isNativeOptions(node.options) &&
        node.native === true);
}
function assertDOMNativeVNode(node) {
    if (!isDOMNativeVNode(node)) {
        throw new Error("Expected DOMNativeVNode");
    }
}
function isDOMNativeCompatibleVNode(node) {
    return isMarshalledSourceReference(node.source);
}

function isNativeVNode(node) {
    return isDOMNativeVNode(node) || isFragmentDOMNativeVNode(node);
}
function assertNativeVNode(node) {
    if (!isNativeVNode(node)) {
        throw new Error("Expected DOMNativeVNode or FragmentDOMNativeVNode");
    }
}
function Native(options, node) {
    if (isNativeVNode(node)) {
        return node;
    }
    const nativeOptions = getNativeOptions(node);
    if (nativeOptions && isDOMNativeCompatibleVNode(node)) {
        return createVNode(isNativeOptions(node.options) ? node : {
            ...node,
            options: {
                ...nativeOptions,
                ...(node.options ? node.options : {}),
            }
        });
    }
    else {
        return createFragment(node);
    }
    function isNativeOptions(options) {
        return options === nativeOptions;
    }
}

function setAttributes(node, documentNode) {
    const attributes = node.options.attributes;
    if (!isNativeAttributesObject(attributes)) {
        return;
    }
    const keys = Object.keys(attributes);
    const lowerKeys = keys.map(key => key.toLowerCase());
    const duplicates = lowerKeys.filter((value, index, array) => {
        const before = array.slice(0, index);
        return before.includes(value);
    });
    if (duplicates.length) {
        throw new Error(`Duplicate keys found for ${duplicates.join(", ")}, this will lead to unexpected behaviour, and is not supported`);
    }
    const toRemove = [];
    // Don't use lower keys here as we need to access attributes
    keys.forEach(key => {
        const value = attributes[key];
        if (value === undefined || value === false) {
            toRemove.push(key);
        }
        else if (value === true) {
            documentNode.setAttribute(key, "");
        }
        else {
            documentNode.setAttribute(key, String(attributes[key]));
        }
    });
    const attributesLength = documentNode.attributes.length;
    // Assume we set all of these attributes, and don't need to check further if there
    if (attributesLength === keys.length && toRemove.length === 0) {
        return;
    }
    for (let attributeIndex = 0; attributeIndex < attributesLength; attributeIndex += 1) {
        const attribute = documentNode.attributes.item(attributeIndex);
        if (lowerKeys.includes(attribute.name.toLowerCase())) {
            continue;
        }
        toRemove.push(attribute.name);
    }
    toRemove.forEach(key => documentNode.removeAttribute(key));
}

function position(elementDetails, tree, reference) {
    const treeIndex = tree.children.indexOf(reference);
    return {
        right() {
            const rightSiblings = tree.children.slice(treeIndex + 1);
            const reference = rightSiblings.find(isRendered.bind(undefined, elementDetails));
            return reference ? elementDetails.rendered.get(reference) : undefined;
        },
        left() {
            const leftSiblings = tree.children.slice(0, treeIndex).reverse();
            const reference = leftSiblings.find(isRendered.bind(undefined, elementDetails));
            return reference ? elementDetails.rendered.get(reference) : undefined;
        }
    };
}
function isRendered(elementDetails, reference) {
    return elementDetails.rendered.has(reference);
}

async function mount(context) {
    const { root, queue, elementDetails, tree, documentNode, node, mountChildren } = context;
    await queue(task);
    if (isElement(documentNode)) {
        await mountChildren(documentNode, node, tree);
    }
    async function taskLifecycleBefore() {
        if (node.options.onBeforeRender) {
            await node.options.onBeforeRender(documentNode);
        }
    }
    async function taskLifecycleAlreadyMounted(currentDocumentNode) {
        // We have a known node for this reference, lets replace that
        if (documentNode !== currentDocumentNode) {
            root.replaceChild(documentNode, currentDocumentNode);
            // Set rendered after adding to DOM, before setting attributes
            elementDetails.rendered.set(node.reference, documentNode);
        }
        if (isElement(documentNode)) {
            await setAttributes(node, documentNode);
        }
    }
    function taskLifecycleFirstChild() {
        // When appending we can set our attributes beforehand
        root.appendChild(documentNode);
    }
    function taskLifecyclePosition({ left: leftFn, right: rightFn }, parent) {
        const right = rightFn();
        if (right) {
            root.insertBefore(documentNode, right);
        }
        else {
            const left = leftFn();
            if (left) {
                const nextSibling = left.nextSibling;
                if (nextSibling) {
                    // The element before has a next sibling, and we don't know about it, so lets
                    // insert before this
                    root.insertBefore(documentNode, nextSibling);
                }
                else {
                    // The element before is the last child
                    root.appendChild(documentNode);
                }
            }
            else {
                const parentPosition = parent?.();
                if (parentPosition) {
                    return taskLifecyclePosition(parentPosition);
                }
                else {
                    // Nothing before it, lets insert to the front
                    root.insertBefore(documentNode, root.firstChild);
                }
            }
        }
    }
    function taskLifecycleExistingChild() {
        taskLifecyclePosition(position(elementDetails, tree, node.reference), () => context.position?.(context));
    }
    async function taskLifecycleMount() {
        // We aren't included yet, lets see where we start
        // Because the node is not included, we can set our attributes ahead of time
        if (isElement(documentNode)) {
            await setAttributes(node, documentNode);
        }
        // If there is nothing rendered, lets append
        if (elementDetails.rendered.size === 0) {
            taskLifecycleFirstChild();
        }
        else {
            taskLifecycleExistingChild();
        }
        // Set rendered after added to DOM
        taskLifecycleRendered();
    }
    function taskLifecycleRendered() {
        elementDetails.rendered.set(node.reference, documentNode);
    }
    async function taskLifecycleCleanupRemovableNodes() {
        // This will only run for the first child that was committed, each after will have no
        // removable until we have a different tree
        //
        // This also only disconnects the node from its parent, if the node is returned again
        // from a retained instance, the node can be re-mounted
        for (const [reference, removableDocumentNode] of getRemovableDocumentNodes()) {
            root.removeChild(removableDocumentNode);
            elementDetails.rendered.delete(reference);
        }
        function getRemovableDocumentNodes() {
            const renderedReferences = [...elementDetails.rendered.keys()];
            return renderedReferences
                .filter(reference => !tree.children.includes(reference))
                .map((reference) => [reference, elementDetails.rendered.get(reference)]);
        }
    }
    async function taskLifecycleAfter() {
        await taskLifecycleCleanupRemovableNodes();
        if (node.options.onAfterRender) {
            await node.options.onAfterRender(documentNode);
        }
    }
    async function task() {
        try {
            await taskLifecycleBefore();
            const currentDocumentNode = elementDetails.rendered.get(node.reference);
            if (currentDocumentNode) {
                await taskLifecycleAlreadyMounted(currentDocumentNode);
            }
            else {
                await taskLifecycleMount();
            }
        }
        finally {
            await taskLifecycleAfter();
        }
    }
}

function createElementDetails() {
    return {
        rendered: new Map(),
        disconnect: new Map()
    };
}
function assertElementDetails(details) {
    if (!isElementDetails(details)) {
        throw new Error("Expected ElementDetails");
    }
}
function isElementDetails(details) {
    function isElementDetailsLike(details) {
        return !!details;
    }
    return isElementDetailsLike(details) && details.rendered instanceof Map && details.disconnect instanceof Map;
}

const CHILD_POSITION = Symbol("DOM Child Position");
class DOMVContext extends WeakVContext {
    constructor(options, weak, eventsPair = createVContextEvents()) {
        super(weak, eventsPair);
        this.options = options;
        this.committing = Promise.resolve();
        this.#queue = async (task) => {
            const promise = this.committing.then(task);
            this.committing = promise;
            await promise;
            if (this.committing === promise) {
                // Does this help?
                this.committing = Promise.resolve();
            }
            await (this.committing = this.committing.then(task));
        };
        this.#position = (context) => {
            const parent = this.options.parent;
            if (!(parent instanceof DOMVContext)) {
                return undefined;
            }
            return parent[CHILD_POSITION]?.(context);
        };
    }
    async hydrate(node, tree) {
        assertNativeVNode(node);
        if (isFragmentDOMNativeVNode(node)) {
            return this.commitChildren(this.options.root, node, tree);
        }
        else if (isDOMNativeVNode(node)) {
            if (!tree) {
                throw new Error("Expected a tree with DOMNativeVNode, entry point should be a FragmentDOMNativeVNode");
            }
            this.eventsTarget.hydrate.add({
                node,
                tree
            });
            const options = node.options;
            if (node.native && node.source && isHydrateOptions(options)) {
                return options.hydrate(node, tree);
            }
            const documentNode = await this.getDocumentNode(node);
            await this.commit(node, documentNode, tree);
        }
    }
    async getDocumentNode(node) {
        const map = this.getWeakMap(node);
        const existingDocumentNode = map.get(this.options.root);
        if ((isElement(existingDocumentNode) || isText(existingDocumentNode)) && isExpectedNode(node, existingDocumentNode)) {
            return existingDocumentNode;
        }
        const documentNode = await getDocumentNode(this.options.root, node);
        map.set(this.options.root, documentNode);
        return documentNode;
    }
    getWeakMap(key) {
        const existing = this.weak.get(key);
        if (existing instanceof WeakMap) {
            return existing;
        }
        const map = new WeakMap();
        this.weak.set(key, map);
        return map;
    }
    childContext(documentNode) {
        const existingChildContext = this.weak.get(documentNode);
        if (existingChildContext instanceof DOMVContext) {
            return existingChildContext;
        }
        const childContext = new DOMVContext({
            root: documentNode,
            parent: this
        }, this.weak, {
            events: this.events,
            target: this.eventsTarget
        });
        this.weak.set(documentNode, childContext);
        return childContext;
    }
    getElementDetails(documentNode) {
        const map = this.getWeakMap(this);
        let elementDetails = map.get(documentNode);
        if (!elementDetails) {
            // If we have no tree, we can make them on the fly
            elementDetails = createElementDetails();
            map.set(documentNode, elementDetails);
        }
        // If we are getting details from within a tree, we expect them!
        assertElementDetails(elementDetails);
        return elementDetails;
    }
    #queue;
    #position;
    [CHILD_POSITION]({ tree }) {
        return undefined;
        // const { parent, reference } = tree;
        // if (!parent) {
        //   return undefined; // No siblings, default behaviour for new root node
        // }
        // if (parent.children.length <= 1) {
        //   return undefined; // No siblings
        // }
        // const { root } = this.options;
        // const referenceIndex = parent.children.indexOf(reference);
        // const elementDetails = this.getElementDetails(root);
        // const parentPosition = position(elementDetails, tree, parent.reference);
    }
    async commit(node, documentNode, tree) {
        const { root } = this.options;
        await mount({
            documentNode,
            elementDetails: this.getElementDetails(root),
            node,
            root,
            tree,
            mountChildren: this.commitChildren.bind(this),
            position: this.#position.bind(this),
            queue: this.#queue.bind(this)
        });
    }
    async commitChildren(documentNode, node, tree) {
        const childContext = this.childContext(documentNode);
        await hydrateChildren(childContext, node, tree);
    }
}
function isHydrateOptions(options) {
    function isHydrateOptionsLike(options) {
        return !!options;
    }
    return isHydrateOptionsLike(options) && typeof options.hydrate === "function";
}

async function render(node, root) {
    if (!node)
        return;
    const context = isElement(root) ? new DOMVContext({ root }) : root;
    await hydrate(context, Native({}, createNode(Fragment, {}, node)));
}

export { DOMVContext, assertElement, isAttributesOptions, isElement, isGetDocumentNodeOptions, isNativeOptions, isOnBeforeRenderOptions, render };
